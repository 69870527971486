/* display: flex;  Use flexbox to position items horizontally */
/* align-items: center;  Center items vertically */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.page-container {
  background-color: #bbebea;
}

.header {
  margin: 5% 6% 3% 6%;
  font-size: 16px;
  font-weight: bold;
  color: #323b60;
  text-align: center;
}

.text-and-photos-container {
  display: flex;
  flex-direction: column;
}

.intro-text {
  justify-content: space-between;
  margin: 2% 5% 2% 5%;
  font-size: 14px;
  color: #323b60;
  text-align: justify;
}

.cyan-line {
  background-color: #50cfcd;
  width: 80%;
  height: 10px;
  border-radius: 25px;
  margin-left: 10%;
}

.volunteers-container {
  background-color: #cea0e4;
  padding-top: 1rem;
}

.volunteers-list-header {
  font-size: 14px;
  font-weight: bold;
  color: #323b60;
  text-align: center;
  margin: 10px;
}

.year-buttons-container {
  display: flex;
  justify-content: center;
  background-color: #cea0e4;
  padding-bottom: 1rem;
  padding-top: 1rem;
  gap: 10px;
}

.year-buttons {
  font-weight: bold;
  background-color: #39455d;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 15px;
  border: none;
  transition: background-color 0.3s, color 0.3s;
}
.year-buttons.active {
  background-color: #50cfcd; /* default or selected */
  color: #39455d;
  transform: scale(1.1);
}
.year-buttons:hover {
  background-color: #feb211;
  color: #39455d;
  transform: scale(1.2);
}

.volunteer-info-container {
  display: flex; /* horizontally */
  flex-direction: row;
  align-items: center; /* vertically */
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.25); /* transparent */
  border-radius: 0.5rem;
  text-align: left;
  overflow: hidden; /* Ensure that content doesn't overflow */
  max-width: 100%; /* Prevent breaking out of containers */
}

.volunteers-list {
  display: grid;
  grid-template-columns: 1fr; /* Single column by default */
  gap: 20px;
  margin: 2% 5% 2% 5%;
  overflow: hidden; /* Prevent overflow */
}

/* Two columns on medium and large screens */
@media (min-width: 768px) {
  .volunteers-list {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }
}

/* For larger screens */
@media (min-width: 1024px) {
  .volunteers-list {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for large screens */
  }
}

.avatar {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 25%;
  margin-right: 1rem;
}

.volunteer-details {
  display: flex;
  flex-direction: column;
  color: #323b60;
  flex-grow: 1;
  max-width: calc(100% - 8.5rem);
}

.name {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Correct alignment for icons */
  white-space: normal;
}

.job-title-skills {
  flex-grow: 1; /* Allow it to grow and take up available space */
  font-size: 14px;
  margin: 2% 0% 2% 0%;
  white-space: normal; /* Allow job title and skills to wrap */
}

.react-icons {
  margin-left: 10px;
  color: #323b60;
}

.link-our-projects {
  display: flex;
  flex-direction: column;
  background-color: #abb2e7;
}

.link-our-projects on:hover {
  cursor: pointer;
}
.link-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: bold;
  color: #323b60;
  text-align: center;
  background-color: #abb2e7;
  border-color: transparent;
}
.link-text on:hover {
  cursor: pointer;
}

.footer-container {
  display: flex;
  justify-content: center;
  background-color: #50cfcd;
  width: 100%;
  padding: 20px;
}

.footer-content {
  display: flex;
  align-items: center; /* Align text and icon vertically */
}
.footer-content a img {
  vertical-align: middle;
}

.footer-text-copyright {
  font-size: 12px;
  color: #323b60;
  margin-right: 10px; /* Adds space between the text and the logo */
}

.counter {
  font-size: 12px;
  color: #bbebea;
  margin-left: 10px;
}
