.title {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 50px;
  text-align: center;
  color: #323b60;
}

/* to wrap all pink containers */
.our-projects-container {
  background-color: #bbebea;
  padding-bottom: 5px;
}

/* Individual project containers */
.our-projects-individual-containers {
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  background-color: #debcef;
  padding: 15px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.project-title {
  color: #323b60;
  padding: 5px 0;
}

.project-textAll {
  width: 100%;
  line-height: 1.5;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
  padding-bottom: 10px;
  font-size: 16px;
  text-align: justify;
  color: #323b60;
}

a {
  color: #323b60;
}

a:visited {
  color: #323b60;
}

.project-textAll h3 {
  padding: 5px;
}

.github-icon {
  margin-top: 10px;
}
