.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #abb2e7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.navbar img /* Pensano Logo*/ {
  cursor: pointer;
  height: 80px;
  width: auto;
  margin-left: 10px;
  padding: 10px;
}

.buttons-container {
  display: flex;
}
.navbar button {
  margin-right: 15px;
  background-color: #feb211;
  color: #39455d;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  border: none;
  transition: background-color 0.3s, color 0.3s;
}

.navbar button:active {
  background-color: #93c7bf;
}

.navbar button:hover {
  background-color: #cea0e4;
  color: #39455d;
  transform: scale(1.1);
}

/* Not in use */
.navbar .team-container {
  margin-left: auto;
  color: #16557b;
}

/* Text inside dropdown menu*/
.menu-item {
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14;
  padding: 4px;
  color: #16557b;
  text-decoration: none;
  font-weight: bold;
  margin-left: 25px;
}

.dropdown-container {
  position: relative;
}

.dropdown {
  display: flex;
  flex-direction: column; /* added to stack menu items vertically */
  position: absolute;
  width: 140px;
  background-color: #d1e3e5;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.makers-logo {
  height: 40px;
  margin-right: 20px;
}
