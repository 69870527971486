.about-page-container {
  background-color: #bbebea;
  padding-bottom: 15px;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* horizontally */
}

.title {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  color: #323b60;
}

.figcaption {
  color: #323b60;
  font-size: 10px;
  text-align: right;
  margin-bottom: 20px;
}

.co-founders-picture {
  margin-top: 20px;
  border-radius: 3%;
}
.about-text {
  justify-content: space-between;
  margin: 1.5% 5% 1.5% 5%;
  font-size: 15px;
  color: #323b60;
  text-align: justify;
}

.about-text a {
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.contact-info {
  display: flex;
  align-items: center; /* horizontally */
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-info p {
  font-size: 14px;
  color: #323b60;
}

.contact-info img {
  margin-left: -20px;
  vertical-align: middle;
}
